import React from "react";
import { FormattedMessage } from "react-intl";

export const TermsLabel = () => (
  <div>
    <FormattedMessage
      id="create_account_form.terms_and_conditions"
      defaultMessage="I agree to the {termsOfService} and {privacyPolicy}"
      values={{
        termsOfService: (
          <a href="/terms" target="_blank">
            <FormattedMessage
              id="create_account_form.terms_of_service"
              defaultMessage="Terms of Service"
            />
          </a>),
        privacyPolicy: (
          <a href="/privacy" target="_blank">
            <FormattedMessage
              id="create_account_form.privacy_policy"
              defaultMessage="Privacy Policy"
            />
          </a>)
      }}
    />
  </div>
);
