import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { NewHeadingLevel } from 'controls/heading';
import { ImmediateProperty } from "utils/react_utils";
import { Form, useFormProps } from "form";
import { makeWebdriverId } from 'utils';
import { SigninForm } from "./signin_form";
import { redirectAfterSignin } from "./signin_utils";
import { ssoTfa, ssoTfaSubmit } from "./signin_actions";


export const TwoFactorAuthenticationSso = props => {
  const history = useHistory();
  const [resetForm, setResetForm] = useState(new ImmediateProperty());
  const [reloading, setReloading] = useState(false);
  const [ssoState] = ssoTfa.useAction();
  const [formProps, tfaSubmitState] = useFormProps(ssoTfaSubmit);
  const { organization_name: organizationName, sso } = ssoState.resources;
  const { redirect } = tfaSubmitState.resources || {};

  /**
   * TwoFactorAuth success. Set reloading and redirect the user.
   */
  const success = () => {
    // Can't handle redirects server-side with xhr, so redirect manually from client-side
    setReloading(true);
    redirectAfterSignin(redirect);
  };

  /**
   * Two-factor authentication has failed. The error message is always either "your email or
   * password is invalid," or "you've reached the maximum number of attempts to log in." Current
   * expected behavior is that we redirect to the signin page and show the error.
   *
   * @param {Object} failProps
   *   @param {array<string>} failProps.globalErrors - Errors not attached to a field.
   *   @param {array<string>} failProps.fieldErrors - Errors attached to a field.
   *   @param {function(array|string)} failProps.clearErrors - Remove field values.
   */
  const failure = failProps => {
    // If client-side validation failed, or an error occurred in the TFA form, don't redirect.
    setReloading(false);
    if (failProps.fieldErrors.length === 0) {
      setResetForm(resetForm.next());
      if (redirect) {
        history.push(redirect);
      } else {
        failProps.removeValues({ otp_attempt: 1 });
      }
    }
  };

  return (
    <NewHeadingLevel>
      <SigninForm
        {...props}
        {...formProps}
        resetNow={resetForm}
        onSubmitSuccess={success}
        onSubmitFailure={failure}
        title={
          <FormattedMessage
            id="sign_in_two_factor_authentication.title"
            defaultMessage="Two-Factor Authentication"
          />}
        singleUse
        subtitle={
          <FormattedMessage
            id="sign_in_sso_two_factor_authentication.subtitle"
            defaultMessage="Please complete the Two-Factor Authentication below to grant {organization} access to sign you in. You will only need to complete this once for this organization."
            values={{ organization: organizationName }}
          />
        }
        buttonText={
          <FormattedMessage
            id="button.sign_in"
            defaultMessage="Sign In"
          />}
        initialValues={{
          ...sso
        }}
        simulateSubmitting={reloading}
        inputs={[
          {
            component: Form.TextField,
            name: 'otp_attempt',
            label: (
              <FormattedMessage
                id="sign_in_two_factor_authentication.authorization_code"
                defaultMessage="Authorization Code"
              />),
            autoFocus: true,
            required: true
          }
        ]}
        webdriverId={makeWebdriverId('sso-tfa')}
      />
    </NewHeadingLevel>
  );
};
