/**
 * A class whose instances process API location data into the structure
 * that our frontend code uses.
 *
 * The module checks whether we've attached API data to the window (via
 * the `_ui2018_init.js.erb` file. If so, we create an instance, feed it
 * that API data, have the instance configure that data and keep the
 * processed versions as instance variables, then default export
 * the instance, for use by the frontend.
 *
 * We also export the class itself for testing purposes.
 */

export class LocationData {
  constructor() {
    this.allowedCountries = [];
    this.allowedCountriesById = [];
    this.allowedStatesOrProvinces = {};
    this.requiresZip = {};
  }

  /**
   * Populates the preexisting, empty instance properties created in the constructor
   *
   * @param allowedCountries - property of the locationData object
   * @param allowedStatesOrProvinces - property of the locationData object
   */


  configureLocationData({ allowedCountries, allowedStatesOrProvinces }) {

    allowedCountries.forEach(countryObj => {
      this.allowedCountries.push({
        displayValue: countryObj.name,
        selectionValue: countryObj.name
      });
      this.allowedCountriesById.push({
        displayValue: countryObj.name,
        selectionValue: countryObj.id
      });

      if (countryObj.zip_required) {
        this.requiresZip[countryObj.id] = this.requiresZip[countryObj.name] = true;
      }
    });


    // if a country has an array of states/province strings associated with it,
    // grab that array and map it to an array of objects
    for (const country of Object.keys(allowedStatesOrProvinces)) {
      this.allowedStatesOrProvinces[country] = this._mapStateProvinceToValue(
        allowedStatesOrProvinces[country]
      );
    }
  }

  /**
   * Initialize the states.
   *
   * @param {Array} incomingLocation
   * @returns {Array} - an array of objects
   * @private
   */
  _mapStateProvinceToValue(incomingLocation) {
    return incomingLocation.map(stateProvince => ({
      displayValue: stateProvince,
      selectionValue: stateProvince
    }));
  }

  /**
   * Get the list of countries.
   *
   * @param {Boolean} countryById - List by id, rather than by name.
   * @returns {Array}
   */
  getAllowedCountries(countryById) {
    return countryById ? this.allowedCountriesById : this.allowedCountries;
  }

  /**
   * Get a list of subdivisions by country.
   *
   * @param {String} country
   * @returns {Array}
   */
  getStatesOrProvinces(country) {
    return this.allowedStatesOrProvinces[country];
  }

  /**
   * Return true if this country code or id requires a zip code.
   *
   * @param {String|int} country - The name of a country, or numeric ID.
   * @returns {boolean}
   */
  isZipRequired(country) {
    return country in this.requiresZip;
  }
}
