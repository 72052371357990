import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes, makeClassName } from 'utils';
import { Form, connectForm } from "form";
import PropTypes from "prop-types";
import { processedLocationData } from 'app_utils/location_data';
import { Button } from "controls/button";
import { TermsLabel } from "./terms_and_conditions_label";
import * as routes from 'utils/routes';
import { createNewUserAccount, createSSOUserAccount } from './signin_actions';
import { SanctionedCountryDialog, showSanctionedCountryDialog } from './sanctioned_country_dialog';
import SocialSignonButtons from 'sso/social_signon_buttons';
import "./create_account_form.sass";

/**
 * The form to create an account.
 *
 * @property {function({propsForFieldGroup:function(string), isSubmitting: boolean})} [additionalFields] -
 *   optional; provide a function to render additional content (form fields)
 *   just above the password field, it will receive an object containing propsForFieldGroup (a
 *   function that - given a field name - returns an object of props suitable for use with form
 *   fields) and isSubmitting (a boolean indicating whether the form is currently
 *   submitting a request to the server)
 *
 * @property {function} onSubmitSuccess - Called on successful submit.
 * @property {string} signinUrl - the path of the corresponding sign-in screen (e.g. if this
 *   component is being used to prompt the user to create an account to accept a badge, the
 *   signinUrl would be the version of the sign-in screen that shows the badge info)
 */
class CreateAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sanctionedCountryDialog: showSanctionedCountryDialog(props.location?.search),
      alreadyTakenSignInPromptDialog: false
    };
  }

  /**
   * The zip code is validated by the server even when it's not used. Remove it when appropriate.
   *
   * @param {Object} vals - Submitted values.
   */
  interceptSubmit = vals => {
    vals = { ...vals };
    if (!processedLocationData.isZipRequired(vals.country_id)) {
      delete vals.zip_or_postal_code;
    }
    this.props.submit(vals);
  };

  /**
   * Form submitted successfully.
   */
  success = () => {
    const redirect =
      this.props.submitResult ? this.props.submitResult[0].redirect : routes.almostDone;

    if (redirect === routes.almostDone) {
      this.props.history.push(redirect);
    } else {
      // The user is signed in. Reload the page to load user data.
      routes.setHref(redirect);
    }
  };

  failure = () => {
    const statusCode = this.props.submitRequest.statusCode;
    const alreadyTakenError = this.props.errors?.email?.[0] === 'has already been taken';

    if (statusCode === 451) {
      this.setState({ sanctionedCountryDialog: true });
    }
    if (statusCode === 422 && alreadyTakenError) {
      this.setState({ alreadyTakenSignInPromptDialog: true });
    }
  };

  handleToSValidation = (_name, val) => {
    return !val
      ? ['Please review the Terms of Service and Privacy Policy and check the box to accept']
      : [];
  };

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    const initialValues = {
      ...(this.props.preFill || {}),
      subscribed_to_marketing_email: false
    };
    const { sanctionedCountryDialog, alreadyTakenSignInPromptDialog } = this.state;
    const closeSanctionedCountryDialog = () => {
      this.setState({ sanctionedCountryDialog: false });
    };
    return (
      <>
        <SanctionedCountryDialog
          show={sanctionedCountryDialog}
          shouldClose={closeSanctionedCountryDialog}
        />
        <div className="create-account-form-container">
          <div
            className={makeClassName(
              "create-account__form-heading",
              this.props.preFill?.badge_id ? '' : 'create-account__grey-form-heading')}
          >
            {
              this.props.headerText ||
                <FormattedMessage
                  id="create_account_form.title"
                  defaultMessage="Create a Credly Account."
                />}
          </div>
          <Form
            className={makeClassName('create-account-form', this.props.className)}
            {...this.props}
            initialValues={initialValues}
            submit={this.interceptSubmit}
            onSubmitSuccess={this.success}
            onSubmitFailure={this.failure}
          >
            {({ propsForFieldGroup, isSubmitting, clearErrors }) => {
              // Create field props based on propsForFieldGroup and auto-fill.
              const isReadOnly = name => !['first_name', 'middle_name', 'last_name'].includes(name) &&
                !!initialValues[name];
              const autoProps = name => ({
                ...propsForFieldGroup(name),
                className: 'create-account-form__field',
                readOnly: isReadOnly(name),
                enclosed: !isReadOnly(name)
              });

              const countryProps = autoProps('country_id');

              return <div className="create-account-form__form">
                {this.props.formIntro}
                {alreadyTakenSignInPromptDialog &&
                  <div className="create-account-form__have-account-prompt">
                    <FormattedMessage
                      id="sign_up.have_account"
                      defaultMessage="Already have an account?"
                    />
                    <Link
                      to={Routes.signinUrl(this.props.preFill?.badge_id)}
                      className="create-account-choose__sign-in-link"
                    >
                      <FormattedMessage
                        id="button.sign_in"
                        defaultMessage="Sign In"
                      />
                    </Link>
                  </div>
                }
                <Form.TextField
                  required
                  label={this.props.intl.formatMessage({
                    id: 'create_account_form.first_name',
                    defaultMessage: 'First Name'
                  })}
                  {...autoProps("first_name")}
                />
                {autoProps("middle_name").value &&
                  <Form.TextField
                    required
                    label={this.props.intl.formatMessage({
                      id: 'create_account_form.middle_name',
                      defaultMessage: 'Middle Name'
                    })}
                    {...autoProps("middle_name")}
                  />
                }
                <Form.TextField
                  required
                  label={this.props.intl.formatMessage({
                    id: 'create_account_form.last_name',
                    defaultMessage: 'Last Name'
                  })}
                  {...autoProps("last_name")}
                />
                {!this.props.hideEmail &&
                  <Form.TextField
                    required
                    label={this.props.intl.formatMessage({
                      id: 'create_account_form.email',
                      defaultMessage: 'Email'
                    })}
                    type="email"
                    {...autoProps("email")}
                    enclosed
                  />
                }
                <Form.CountryTypeaheadSelectOrDropdown
                  required
                  useId
                  label={this.props.intl.formatMessage({
                    id: 'create_account_form.country_territory',
                    defaultMessage: 'Country/Territory'
                  })}
                  placeholder={this.props.intl.formatMessage({
                    id: 'create_account_form.country_territory_placeholder',
                    defaultMessage: 'Select Country/Territory'
                  })}
                  autoComplete="off"
                  {...countryProps}
                  handleChange={(name, value) => {
                    countryProps.handleChange(name, value);
                    clearErrors('zip_or_postal_code');
                  }}
                />
                {processedLocationData.isZipRequired(countryProps.value) && (
                  <Form.TextField
                    required
                    label={this.props.intl.formatMessage({
                      id: 'create_account_form.zip_or_postal_code',
                      defaultMessage: 'Zip or Postal Code'
                    })}
                    {...autoProps("zip_or_postal_code")}
                  />
                )}
                {
                  this.props.additionalFields &&
                  this.props.additionalFields({ propsForFieldGroup: autoProps, isSubmitting })
                }
                {!initialValues.social &&
                  <Form.PasswordField
                    required
                    label={this.props.intl.formatMessage({
                      id: 'create_account_form.password',
                      defaultMessage: 'Password'
                    })}
                    {...autoProps("password")}
                  />
                }
                <Form.Checkbox
                  label={this.props.intl.formatMessage({
                    id: 'create_account_form.news_and_updates',
                    defaultMessage: 'Send me occasional news and updates on professional growth opportunities.'
                  })}
                  {...propsForFieldGroup("subscribed_to_marketing_email")}
                />
                <Form.Checkbox
                  {...propsForFieldGroup("terms_and_policy_accepted")}
                  label={<TermsLabel/>}
                  suppressErrorLabel
                  validate={this.handleToSValidation}
                  id="create_account_form__terms_and_policy"
                />
                <div className="create-account-form__buttons">
                  <Form.Submit loading={isSubmitting}>
                    <FormattedMessage
                      id="button.create_account"
                      defaultMessage="Create Account"
                    />
                  </Form.Submit>
                  <SocialSignonButtons badgeId={this.props.preFill?.badge_id} isSamlSignup={this.props.preFill?.is_saml_signup}/>
                  <Button type="min" to={this.props.signinUrl}>
                    <FormattedMessage
                      id="button.have_account"
                      defaultMessage="I have an account"
                    />
                  </Button>
                </div>
              </div>;
            }}
          </Form>
        </div>
      </>
    );
  }
}


CreateAccountForm.propTypes = {
  additionalFields: PropTypes.func,
  preFill: PropTypes.object,
  signinUrl: PropTypes.string.isRequired,
  hideEmail: PropTypes.bool,

  // from injectIntl
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

const resourceHandler = (ownProps) => {
  if (ownProps.preFill && ownProps.preFill.token) {
    return createSSOUserAccount;
  } else {
    return createNewUserAccount;
  }
};

const CreateAccountFormWithIntl = injectIntl(CreateAccountForm);

const Connected = withRouter(
  connect(
    (state, ownProps) => ({
      submitRequest: resourceHandler(ownProps).getRequestDetails(state) || {},
      submitResult: resourceHandler(ownProps).getResources(state)
    })
  )(connectForm({ resourceHandler })(CreateAccountFormWithIntl))
);

export { Connected as CreateAccountForm };
export const testing = { CreateAccountForm: CreateAccountFormWithIntl };
