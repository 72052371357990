import React from 'react';
import { Heading } from 'controls/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import './almost_done.sass';
import { HidesHeaderButtons } from 'header/hides_header_buttons';
import { FormattedMessage } from "react-intl";

export const AlmostDone = () => {
  return (
    <HidesHeaderButtons>
      <div className="container almost-done">
        <div className="almost-done__icon-container">
          <FontAwesomeIcon className="almost-done__icon-email" icon={faEnvelope} />
          <div aria-hidden="true" className="almost-done__count-badge">1</div>
        </div>
        <Heading appearance="badge-name-hero">
          <FormattedMessage
            id="create_account.confirm_your_email"
            defaultMessage="Confirm Your Email"
          />
        </Heading>

        <div className="almost-done__text">
          <div>
            <FormattedMessage
              id="create_account.almost_done_1"
              defaultMessage="Thank you for creating an account with Credly!"
            />
          </div>
          <div>
            <FormattedMessage
              id="create_account.almost_done_2"
              defaultMessage="To finish setting up your account, please check your email to confirm the address you
              provided."
            />
          </div>
        </div>

        <div className="almost-done__spam">
          <FormattedMessage
            id="create_account.almost_done_spam"
            defaultMessage="If you don't see our message, please check your spam folder!"
          />
        </div>
      </div>
    </HidesHeaderButtons>
  );
};
