import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connectForm, Form } from 'form';
import { setHref, DEFAULT_URL } from 'utils/routes';
import { NewHeadingLevel } from 'controls/heading';
import { getProfileAction } from 'profile';
import { FormattedMessage } from 'react-intl';
import { makeWebdriverId } from 'utils';
import { changePassword } from './signin_actions';
import { SigninForm } from './signin_form';
import { RouterPropTypes } from 'utils/prop_types';

const ChangePassword = (props) => {
  const { location, initialValues } = props;
  const urlParams = new URLSearchParams(location.search);

  const success = () => setHref(initialValues.redirect || DEFAULT_URL);

  return (
    <NewHeadingLevel>
      <SigninForm
        {...props}
        onSubmitSuccess={success}
        title={
          <FormattedMessage
            id="forgot_password.enter_your_new_password"
            defaultMessage="Enter your new password."
          />}
        webdriverId={makeWebdriverId('change-password')}
        initialValues={{
          _method: 'put',
          reset_password_token: urlParams.get('reset_password_token')
        }}
        buttonText={
          <FormattedMessage
            id="forgot_password.change_password"
            defaultMessage="Change Password"
          />}
        inputs={[
          {
            component: Form.PasswordField,
            name: 'password',
            label: <FormattedMessage id="forgot_password.password" defaultMessage="Password"/>,
            required: true
          },
          {
            component: Form.PasswordField,
            name: 'password_confirmation',
            label: <FormattedMessage id="forgot_password.confirm_password" defaultMessage="Confirm Password"/>,
            required: true
          }
        ]}
      />
    </NewHeadingLevel>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: getProfileAction.getResources(state) || { anonymous: true }
  };
};


const Connected = withRouter(connectForm({ resourceHandler: changePassword })(ChangePassword));
const ConnectedWithUser = connect(mapStateToProps)(Connected);

export { ConnectedWithUser as ChangePassword };
export { ChangePassword as testing };

ChangePassword.propTypes = {
  initialValues: PropTypes.shape({
    redirect: PropTypes.string
  }),
  history: PropTypes.shape({
    search: PropTypes.string
  }),
  location: RouterPropTypes.location
};
