import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProfileAction } from 'profile';
import { Routes } from 'utils';

const isPresent = (val) => {
  return val && val.trim() !== '';
};

/**
 * Higher-order component that wraps a transcript download-related view with logic to redirect to
 * the page where the user can take the next step to access the transcript: either sign in/sign up,
 * provide additional profile data, or actually download the transcript.
 *
 * @param {
 *   { [isDownload]: boolean, [isUpdateProfile]: boolean, [isSigninSignup]: boolean}
 * } currentViewOpts - object containing one true property out of the set isDownload,
 *   isProfileUpdate, isSigninSignup, indicating which is the current page
 * @returns {Function}
 */
export const withTranscriptDownloadDispatch = (currentViewOpts) => {
  return (WrappedComponent) => {
    /* eslint react/prop-types: 0 */
    class TranscriptDispatchComponent extends Component {
      state = { componentDidMountRan: false };

      componentDidMount() {
        const userVanitySlug = this.props.passThroughProps.userVanitySlug;
        const transcriptRecipientId = this.props.passThroughProps.transcriptRecipientId;
        const user = this.props.user;

        if (!this.props.user.anonymous) {
          if (isPresent(user.current_position_name) && isPresent(user.current_organization_name)) {
            if (!currentViewOpts.isDownload) {
              this.props.history.push(
                Routes.transcriptUrl.download(userVanitySlug, transcriptRecipientId)
              );
            }
          } else {
            if (!currentViewOpts.isUpdateProfile) {
              this.props.history.push(
                Routes.transcriptUrl.downloadViaUpdateProfile(userVanitySlug, transcriptRecipientId)
              );
            }
          }
        } else {
          if (!currentViewOpts.isSigninSignup) {
            this.props.history.push(
              Routes.transcriptUrl.downloadViaSignup(userVanitySlug, transcriptRecipientId)
            );
          }
        }
        this.setState({ componentDidMountRan: true });
      }

      render() {
        if (this.state.componentDidMountRan) {
          return <WrappedComponent {...this.props.passThroughProps} />;
        } else {
          return <Fragment/>;
        }
      }
    }

    return connect(
      (state) => ({ user: getProfileAction.getResources(state) || { anonymous: true } }),
      null,
      (stateProps, _dispatchProps, passThroughProps) => ({ ...stateProps, passThroughProps })
    )(withRouter(TranscriptDispatchComponent));
  };
};
