import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Routes } from 'utils';
import { withCreateAccountSSORedirect } from './with_create_account_sso_redirect';
import { SigninBadgeRow } from './signin_badge_row';
import { AcceptHomepageWrapper } from "./accept_homepage_wrapper";
import { CreateAccountForm } from './create_account_form';
import './create_account_accept.sass';

/**
 * Component for signing up to accept a badge.  Shows some basic information about the badge (such
 * as badge name and visual), tailors the marketing content to the badge issuer, and pre-fills the
 * user email address.
 *
 * @property {string} badgeId - the ID of the badge the user is attempting to accept
 * @property {object} [preFill] - an object containing values to pre-fill in the form data; used by
 *   SSO create account form to pre-populate fields with SSO registration data
 *
 * @property {object} apiBadge - the badge information retrieved from the API; provided by connect
 * @property {function(badgeId: string)} apiFetchBadge - a function to initiate a request to
 *   retrieve the badge information from the API; provided by connect
 */
export const CreateAccountAccept = props => {
  return (
    <AcceptHomepageWrapper badgeId={props.badgeId} type="signup">
      {(badge) => (
        <div className="create-account-accept">
          <CreateAccountForm
            preFill={{
              ...props.preFill,
              badge_id: props.badgeId,
              email: props.preFill.email || badge.recipient_email
            }}
            badge={badge}
            headerText={
              <FormattedMessage
                id="sign_up.header"
                defaultMessage="Create a Credly account to accept your badge."
              />}
            signinUrl={Routes.signinUrl(props.badgeId)}
            formIntro={
              <SigninBadgeRow
                className="d-flex d-md-none"
                badge={badge}
                appearance="min"
              />
            }
          />
        </div>
      )}
    </AcceptHomepageWrapper>
  );
};

CreateAccountAccept.propTypes = {
  badgeId: PropTypes.string.isRequired,
  preFill: PropTypes.object
};

CreateAccountAccept.defaultProps = {
  preFill: {}
};

export const CreateAccountAcceptWithSSORedirect = withCreateAccountSSORedirect(CreateAccountAccept);
