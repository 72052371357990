import React, { Component } from 'react';
import { connect } from "react-redux";
import { connectForm, Form } from 'form';
import { Link, withRouter } from 'react-router-dom';
import { signinUrl } from 'utils/routes';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { recoverPassword } from './signin_actions';
import { SigninForm } from './signin_form';
import { FormattedMessage } from 'react-intl';
import { oauthUiOptionsAction } from "./oauth_ui_options_action";

import './forgot_password.sass';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
  }


  /**
   * The form was submitted.
   */
  success = () => {
    this.setState({ success: true });
  };


  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    if (this.state.success) {
      return (
        <NewHeadingLevel>
          <div className="container forgot-password forgot-password__success">
            <Heading appearance="plain-text">
              <FormattedMessage
                id="forgot_password.recovery_email"
                defaultMessage="If you have a Credly account, you should receive a password recovery
              email in a few minutes."
              />
            </Heading>

            <div>
              <Link to={signinUrl()}>
                <FormattedMessage
                  id="forgot_password.sign_in"
                  defaultMessage="Sign in"
                />
              </Link>.
            </div>
          </div>
        </NewHeadingLevel>
      );
    }

    return (
      <NewHeadingLevel>
        <SigninForm
          {...this.props}
          onSubmitSuccess={this.success}
          subtitle={
            <FormattedMessage
              id="forgot_password.title"
              defaultMessage="Enter your email address, and we'll send you an email with instructions for how to reset your password."
            />}
          buttonText={
            <FormattedMessage
              id="forgot_password.send_instructions"
              defaultMessage="Send Instructions"
            />
          }
          inputs={[
            {
              component: Form.TextField,
              type: 'email',
              name: 'email',
              label: (
                <FormattedMessage
                  id="forgot_password.email"
                  defaultMessage="Email"
                />
              ),
              required: true
            }
          ]}
          useMinimalForm={this.props.oauthUiOptions?.use_minimal_sign_in_screen}
        />
      </NewHeadingLevel>
    );
  }
}

const Connected = connectForm({ resourceHandler: recoverPassword })(
  withRouter(connect(
    (state, ownProps) => {
      const params = new URLSearchParams(ownProps.location.search);
      const oauthAppId = params.get('oauthAppId');
      return {
        oauthUiOptions: oauthUiOptionsAction.get('oauthUiOptions', oauthAppId || 'fake').getResources(state) || {}
      };
    }
  )(ForgotPassword))
);
export { Connected as ForgotPassword };
