import { useEffect } from 'react';

/**
 * Appends script to document body to be used in the component.
 * @param {String} url script url to append
 * @param {Object} options options to add to script tag, async is true by default
 * @returns {(function(): void)|*} function to clean up script
 */
export const appendScript = (url, options = { async: true }) => {
  const script = document.createElement('script');
  script.src = url;
  Object.keys(options).forEach(key => {
    script[key] = options[key];
  });

  document.body.appendChild(script);
  return () => {
    document.body.removeChild(script);
  };
};

/**
 * Memoize function to append a script to the body of the document.
 * @param {String} url script url to append
 * @param {Object} options options to add to script tag, async is true by default
 */
export const useScript = (url, options = { async: true }) => {
  useEffect(() => {
    return appendScript(url, options);
  }, []);
};
