import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { LoadingSpinner } from 'controls/loading_spinner';
import { Homepage } from 'pages/homepage/homepage';
import { publicBadgeActions } from 'badges';

/**
 * A wrapper for the Accept via Create Account workflow. Retrieves the badge for use in the
 * descendants. This is necessary for the common layout that is present in pages like
 * CreateAccountAccept, CreateAccountChooseSignupOption, and SigninAccept
 * @param {Object} props
 *  @param {String} props.badgeId The badge id to retrieve.
 *  @param {Object} props.canvasFocusRef The ref object for Credfetti™
 *  @param {Function} props.children A function that take the retrieved badge and renders a form
 * @returns {JSX.Element} The full Accept form
 */
export const AcceptHomepageWrapper = props => {
  const { badgeId, canvasFocusRef, type, children, ...otherProps } = props;
  const [action] = publicBadgeActions.useOnMount('fetch', badgeId);
  const badge = action.resources;
  const homepageProps = {
    ...otherProps,
    isSignUpPendingBadge: type === 'signup',
    isSignInPendingBadge: type === 'signin'
  };

  if (action.status.pending) {
    return <LoadingSpinner position="below" />;
  } else if (!badge || action.status.failed || badge.state === 'failed') {
    return (
      <div className="container">
        <div className="accept-homepage-wrapper__invalid-badge-msg">
          <FormattedMessage
            id="sign_up.not_valid_badge"
            defaultMessage="Sorry. That badge is not valid."
          />
        </div>
      </div>);
  }

  return (
    <Homepage
      {...homepageProps}
      canvasFocusRef={canvasFocusRef}
      headerText={
        <FormattedMessage
          id="sign_up.supplemental_header"
          defaultMessage="Congratulations on a badge well-earned!"
        />
      }
      badge={badge}
      bodyContent={
        <FormattedMessage
          id="homepage.header_text.credly_platform"
          defaultMessage="Create your Credly account to claim your badge and join millions of professionals in sharing your achievements online."
        />
      }
    >
      {children(badge)}
    </Homepage>
  );
};

AcceptHomepageWrapper.propTypes = {
  badgeId: PropTypes.string.isRequired,
  canvasFocusRef: PropTypes.object,
  children: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['signin', 'signup']).isRequired
};
