import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Routes } from 'utils';
import { ssoData } from './signin_actions';

/**
 * Higher-order component to wrap create account views and redirect to the SSO registration URL if
 * the Redux store already contains data for an SSO workflow.
 *
 * @param {Function|class} WrappedComponent
 */
export const withCreateAccountSSORedirect = (WrappedComponent) => {
  /* eslint react/prop-types: 0 */
  class RedirectComponent extends Component {
    state = { componentDidMountRan: false };

    componentDidMount() {
      if (this.props.isSSO) {
        this.props.history.replace(Routes.ssoSignupUrl());
      }
      this.setState({ componentDidMountRan: true });
    }

    render() {
      if (this.state.componentDidMountRan) {
        return <WrappedComponent {...this.props.passThroughProps} />;
      } else {
        return <Fragment/>;
      }
    }
  }

  const ConnectedRedirectComponent = connect(
    (state) => {
      const sso = ssoData.getResources(state) || {};
      return { isSSO: sso && !!sso.user };
    },
    null,
    (stateProps, _dispatchProps, passThroughProps) => ({ ...stateProps, passThroughProps })
  )(withRouter(RedirectComponent));

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ConnectedRedirectComponent.displayName = `WithCreateAccountSSORedirect(${displayName})`;
  return ConnectedRedirectComponent;
};
