import React, { useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import './microsoft_sso_button.sass';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import MicrosoftLogo from 'images/microsoft-logo.svg';
import { Action } from 'utils/action';
import { LoadingSpinner } from 'controls/loading_spinner';
import { useAlerts } from 'alert';
import { getRailsEnv } from 'app_utils';

export const signinViaMicrosoft = new Action({
  resourceType: 'signinViaMicrosoft',
  requestKey: 'signinViaMicrosoft',
  id: 'signinViaMicrosoft',
  url: '/users/microsoft_sso',
  effect: 'update',
  method: 'POST'
});

const MICROSOFT_SSO_BUTTON_ID = 'microsoft_sso_btn';
const MICROSOFT_SSO_LOGIN_REDIRECT = '/users/sign_in';
const MICROSOFT_SSO_CLIENT_ID = window.initialAppValues.sso?.microsoft?.clientId;
const MICROSOFT_AZURE_SSO_TENANT_ID = window.initialAppValues.sso?.microsoft?.tenantId;
const MICROSOFT_AZURE_AUTHORITY_SUFFIX = (getRailsEnv() === 'production') ? 'common' : MICROSOFT_AZURE_SSO_TENANT_ID;

const pca = new PublicClientApplication({
  auth: {
    clientId: MICROSOFT_SSO_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MICROSOFT_AZURE_AUTHORITY_SUFFIX}/`
  }
});

const scopes = ["user.read", "profile", "email"];

const Button = ({ badgeId }) => {
  const [loading, setLoading] = useState(false);

  const alerts = useAlerts();

  const [signinViaMicrosoftActionState, signinViaMicrosoftActionPerform] =
    signinViaMicrosoft.useAction();

  const handleLoginCallback = () => {
    setLoading(true);

    pca.acquireTokenPopup(
      { scopes: scopes, redirectUri: `${document.location.origin}${MICROSOFT_SSO_LOGIN_REDIRECT}` }
    ).then(function(accessTokenResponse) {
      const idToken = accessTokenResponse.idToken;
      return signinViaMicrosoftActionPerform({ credential: idToken, badge_id: badgeId });
    }).catch(function() {
      setLoading(false);
      alerts.add({
        type: 'error',
        text: 'There was an error logging you in with your Microsoft account, please try a different method.'
      });
    });
  };

  const handleLogin = React.useCallback(
    handleLoginCallback, [badgeId, signinViaMicrosoftActionPerform, setLoading]
  );

  useEffect(() => {
    if (signinViaMicrosoftActionState.resources &&
        signinViaMicrosoftActionState.resources.redirectUri) {
      setLoading(false);
      window.location.assign(signinViaMicrosoftActionState.resources.redirectUri);
    }
  }, [signinViaMicrosoftActionState.resources]);

  return (
    <div className="microsoft-sso-button__responsive-container">
      <div role="button" id={MICROSOFT_SSO_BUTTON_ID} onClick={handleLogin} className="microsoft-sso-button__m-sso-button">
        {loading
          ? <LoadingSpinner position="default" size="small" />
          : (
            <>
              <img src={MicrosoftLogo} className="microsoft-sso-button__logo" />
              <span>
                <FormattedMessage
                  id="sign_in.with_microsoft_account"
                  defaultMessage="Sign in with Microsoft"
                />
              </span>
            </>
          )
        }
      </div>
    </div>
  );
};

const MicrosoftSSOButton = ({ badgeId }) => {
  return (
    <MsalProvider instance={pca}>
      <Button badgeId={badgeId}/>
    </MsalProvider>
  );
};

MicrosoftSSOButton.propTypes = {
  badgeId: PropTypes.string
};

Button.propTypes = {
  badgeId: PropTypes.string
};

export default MicrosoftSSOButton;
