import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { Form } from 'form';
import { Routes } from 'utils';
import { Button } from 'controls/button';
import { ConfettiAnimation } from 'controls/confetti_animation';
import { SigninBadgeRow } from './signin_badge_row';
import { AcceptHomepageWrapper } from "./accept_homepage_wrapper";
import { withCreateAccountSSORedirect } from './with_create_account_sso_redirect';
import SocialSignonButtons from "sso/social_signon_buttons";
import "./create_account_choose_sign_up.sass";

const CreateAccountChooseSignupOption = props => {
  const canvasFocusRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      ConfettiAnimation({
        focusElement: canvasFocusRef?.current,
        alignment: 'center',
        zIndex: 1000
      });
    }, 500);
  }, []);

  return (
    <AcceptHomepageWrapper
      badgeId={props.badgeId}
      canvasFocusRef={canvasFocusRef}
      type="signup"
    >
      {(badge) => (
        <div className="create-account-choose__container">
          <div className="create-account-choose__form-container">
            <div className="create-account-choose__form-heading">
              <FormattedMessage
                id="sign_up.header"
                defaultMessage="Accept your badge"
              />
            </div>
            <SigninBadgeRow
              className="d-flex d-md-none"
              badge={badge}
              appearance="min"
            />
            <Form.TextField
              name="email"
              enclosed
              readOnly
              label={(
                <FormattedMessage
                  id="create_account_form.email"
                  defaultMessage="Email"
                />)}
              type="email"
              value={badge.recipient_email}
            />
            <Button
              className="create-account-choose__traditional-signup"
              type="primary"
              size="full"
              to={Routes.signupUrl(props.badgeId)}
            >
              <FormattedMessage id="sign_up.create-an-account" defaultMessage="Create an account"/>
            </Button>
            <SocialSignonButtons badgeId={props.badgeId}/>
            <div className="create-account-choose__toggle_sign_up">
              <div>
                <FormattedMessage
                  id="sign_up.have_account"
                  defaultMessage="Already have an account?"
                />
              </div>
              <Link
                to={Routes.signinUrl(props.badgeId)}
                className="create-account-choose__sign-in-link"
              >
                <FormattedMessage
                  id="button.sign_in"
                  defaultMessage="Sign In"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </AcceptHomepageWrapper>
  );
};

CreateAccountChooseSignupOption.propTypes = {
  badgeId: PropTypes.string
};

const CreateAccountChooseSignupOptionSSORedirect =
  withCreateAccountSSORedirect(CreateAccountChooseSignupOption);
export { CreateAccountChooseSignupOptionSSORedirect as CreateAccountChooseSignupOption };
