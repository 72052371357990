import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";

export const LinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};

LinkWithQuery.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
};
