import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import AppleSSOButton from "./apple/apple_sso_button";
import GoogleSSOButton from "./google/google_sso_button";
import MicrosoftSSOButton from "./microsoft/microsoft_sso_button";
import { isAppleSSOEnabled, isGoogleSSOEnabled, isMicrosoftSSOEnabled } from '../pages/signin/signin_utils';

import "./social_signon_buttons.sass";

const socialSignonEnabled = (isSamlSignup) => {
  if (isSamlSignup === 'true') {
    return false;
  }
  return isGoogleSSOEnabled() || isMicrosoftSSOEnabled() || isAppleSSOEnabled();
};

export default function SocialSignonButtons({ badgeId, isSamlSignup }) {
  if (!socialSignonEnabled(isSamlSignup)) {
    return null;
  }

  return (
    <div className="social-signon-buttons__sso-content">
      <div className="social-signon-buttons__or">
        <FormattedMessage id="sign_in.or" defaultMessage="OR"/>
      </div>
      {isGoogleSSOEnabled() && <GoogleSSOButton key="google" badgeId={badgeId} />}
      {isMicrosoftSSOEnabled() && <MicrosoftSSOButton key="microsoft" badgeId={badgeId} />}
      {isAppleSSOEnabled() && <AppleSSOButton key="apple" badgeId={badgeId} />}
    </div>
  );
};

SocialSignonButtons.propTypes = {
  badgeId: PropTypes.string,
  isSamlSignup: PropTypes.string
};
