import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'controls/loading_spinner';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { nonceTokensActions } from 'pages/signin/signin_actions';
import { CreateAccount } from 'pages/signin/create_account';

/**
 * Component for signing up from an email to accept a connection to an employer. Uses
 * the nonce token to query for information about the employment to prefill the email field.
 *
 * @property {string} nonceToken - User friendly nonce token string used to query for
 * the resource of the nonce token owner, in this case the employee record.
 */
export const CreateAccountEmployment = props => {
  const [action] = nonceTokensActions.useOnMount('show', props.nonceToken);
  const nonceTokenResource = action.resources;

  useRaiseOnFailedRequest(action.status, props.nonceToken);

  if (action.status.pending) {
    return <LoadingSpinner position="below" />;
  }

  return (
    <CreateAccount preFill={{ email: nonceTokenResource.resource.email }}/>
  );
};

CreateAccountEmployment.propTypes = {
  nonceToken: PropTypes.string.isRequired
};
