export const routes = {
  signin: '/users/sign_in',
  tfa: '/users/sessions/two_factor_authentication',
  tfaSso: '/users/sessions/two_factor_authentication_sso',
  forgotPassword: '/users/password/new',
  editPassword: '/users/password/edit',
  acceptViaSignin: '/badges/:id/accept_via_sign_in',
  accept: '/badges/:id/accept',
  acceptViaChooseSignup: '/badges/:id/accept_via_choose_sign_up',
  acceptViaSignUp: '/badges/:id/accept_via_sign_up',
  acceptEmploymentViaSignUp: '/employments/:nonceToken/accept_via_sign_up',
  signUp: '/users/sign_up',
  almostDone: '/registrations/almost_done',
  confirmYourEmail: '/registrations/confirm_your_email/:id',
  sso: '/users/registrations/sso',
  transcriptViaSignUp: '/users/:user_vanity_slug/transcripts/:id/download_via_sign_up',
  transcriptViaSignIn: '/users/:user_vanity_slug/transcripts/:id/download_via_sign_in'
};

export const signinRouteList = Object.values(routes);
