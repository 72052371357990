import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeClassName } from 'utils';
import { Heading } from 'controls/heading';
import { Form, textFieldTypes } from 'form';
import { prepareSigninSubmit } from './signin_utils';
import { FormattedMessage } from "react-intl";

import './signin_form.sass';

/**
 * The various signin forms are very similar in structure. This encapsulation eliminates a lot of
 * boilerplate and CSS.
 *
 * @property {Object} initialValues - Form values. utf8 and authenticity_token are always included.
 * @property {String} title - The title of the section.
 * @property {String} subtitle - The subtitle.
 * @property {String} buttonText - The text of the submit button.
 * @property {String|{name: String, value: String}} [webdriverId] - optional arguments (either just
 *   a name or an object containing name and value) to use with {@link makeWebdriverId} in order
 *   to identify the top-level form element
 * @property {React.element} otherButton - A second button, if there is one.
 * @property {String} createAccountUrl - The URL for the create account link. Omit if the link
 *   should not appear.
 * @property {Boolean} linksOpenInNewTab - Links open in a new tab.
 * @property {array<object>} inputs - Form inputs.
 *   @property {function|class} inputs[].component - The class of the component.
 *   @property {String} inputs[].name - The name of the input field.
 *   @property {String} inputs[].label - The input label.
 *   @property {Boolean} inputs[].required - Is the field required?
 * @property {function(object)} onBeforeSubmit - Called right before the form submits.
 * @property {Boolean} simulateSubmitting - Act like the form is being submitted, even when it's
 *   not. This is useful if the container component is doing something that requires a delay, such
 *   as reloading the page.
 * @property {string} formContainerClassName - Class name for the form container to customize styles,
 * useful to customize margins and padding
 * @property {string} formClassName - Class name to customize the actual form, useful for customizing
 * styles such as box shadow and border radius.
 */
export const SigninForm = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    webdriverId,
    otherButton,
    createAccountUrl,
    linksOpenInNewTab,
    inputs,
    onBeforeSubmit,
    simulateSubmitting,
    formContainerClassName,
    formClassName,
    formIntro,
    submit,
    useMinimalForm,
    ssoContent,
    dialogs,
    additionalSubtext
  } = props;
  /**
   * Render the title. If there's a subtitle, the title will be bold. Otherwise, the title is
   * plain text.
   *
   * @returns {JSX.Element}
   */
  const renderTitle = () => {
    if (!title && !subtitle) { return null; }

    return (
      <div className="signin-form__header">
        {title && <Heading appearance="action-page-head">{title}</Heading>}
        {subtitle && <div className="signin-form__subtitle">{subtitle}</div>}
      </div>
    );
  };

  /**
   * Render the "Create Account" link.
   *
   * @returns {JSX.Element}
   */
  const renderCreateAccountLink = () => {
    if (!createAccountUrl) { return null; }

    const link = linksOpenInNewTab
      ? (
        <a href={createAccountUrl} target="_blank">
          <FormattedMessage
            id="sign_in_form.create_account"
            defaultMessage="Create account"
          />
        </a>
      )
      : (
        <Link to={createAccountUrl}>
          <FormattedMessage
            id="sign_in_form.create_account"
            defaultMessage="Create account"
          />
        </Link>
      );

    return <div className="container signin-form__create-account">{link}</div>;
  };


  /**
   * Override submit to combine values into a user object for Devise.
   *
   * @param {Object} values
   */
  const signinSubmit = values => {
    onBeforeSubmit && onBeforeSubmit(values);
    submit(prepareSigninSubmit(values));
  };

  const formContainerClass = makeClassName("signin-form", formContainerClassName);
  const formClass = makeClassName(
    "signin-form__form",
    formClassName,
    useMinimalForm && "signin-form__form--minimal"
  );

  return (
    <div className={formContainerClass} {...webdriverId}>
      <Form
        {...props}
        className={formClass}
        submit={signinSubmit}
      >
        {({ propsForFieldGroup, isSubmitting }) =>
          <>
            {renderTitle()}
            {formIntro}
            {inputs && inputs.map(input => {
              const ComponentName = input.component;

              return (
                <ComponentName
                  className={makeClassName("signin-form__field", input.className)}
                  type={input.type}
                  key={input.name}
                  {...propsForFieldGroup(input.name)}
                  label={input.label}
                  enclosed
                  required={input.required}
                  requiredNoStar
                />
              );
            })}
            {otherButton}
            <div className="signin-form__submit">
              <Form.Submit
                type="primary"
                size="full"
                loading={isSubmitting || simulateSubmitting}
              >
                {buttonText}
              </Form.Submit>
            </div>
            {ssoContent}
            {!!dialogs &&
              <div className="signin-form__dialogs">
                {dialogs}
              </div>
            }
            {renderCreateAccountLink()}
            {additionalSubtext &&
              <div className="signin-form__additional-subtext">
                {additionalSubtext}
              </div>
            }
          </>
        }
      </Form>
    </div>
  );
};

SigninForm.defaultProps = {
  webdriverId: {}
};

SigninForm.propTypes = {
  ...Form.propTypes,
  initialValues: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  buttonText: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  otherButton: PropTypes.node,
  ssoContent: PropTypes.node,
  dialogs: PropTypes.array,
  createAccountUrl: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    // "func" is for classes. "object" is for lazy()-loaded components.
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.object.isRequired
    ]),
    className: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.oneOf(textFieldTypes)
  })),
  linksOpenInNewTab: PropTypes.bool,
  webdriverId: PropTypes.object,
  onBeforeSubmit: PropTypes.func,
  simulateSubmitting: PropTypes.bool,
  formContainerClassName: PropTypes.string,
  formClassName: PropTypes.string,
  additionalSubtext: PropTypes.string,
  useMinimalForm: PropTypes.bool
};
