import PropTypes from 'prop-types';
import './confetti_animation.sass';
import './confetti';

export const ConfettiAnimation = ({
  parentElement,
  focusElement,
  alignment = 'center',
  zIndex = 1000,
  origin = { x: 0.5, y: 0.4 }
}) => {
  const defaults = {
    origin,
    ticks: 170,
    parentElement,
    zIndex
  };

  if (focusElement) {
    if (alignment === 'center') {
      const rect = focusElement?.getBoundingClientRect();
      const fullHeight = window.document.documentElement.scrollHeight;
      const scrollY = window.scrollY;

      const animationPositionX = rect ? ((rect.x + rect.width / 2) / window.innerWidth) : 0.3;
      const animationPositionY = rect ? ((rect.y + scrollY + rect.height / 2) / fullHeight) : 0.5;

      defaults.origin = {
        x: animationPositionX,
        y: animationPositionY
      };
    }
  }

  const fire = (particleRatio, opts) => {
    window.confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(200 * particleRatio)
    }));
  };

  fire(0.25, { spread: 26, startVelocity: 55 });
  fire(0.2, { spread: 60 });
  fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
  fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
  fire(0.1, { spread: 120, startVelocity: 45 });

  return null;
};

ConfettiAnimation.propTypes = {
  origin: PropTypes.object,
  parentElement: PropTypes.node,
  focusElement: PropTypes.node,
  alignment: PropTypes.oneOf(['center']),
  zIndex: PropTypes.number
};
