import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ConfettiAnimation } from "controls/confetti_animation";
import { SignIn } from './signin';
import { SigninBadgeRow } from './signin_badge_row';
import { chooseSignupUrl } from "utils/routes";
import { AcceptHomepageWrapper } from './accept_homepage_wrapper';
import './signin_accept.sass';



/**
 * The sign-in-to-accept-badge page.
 */
export const SignInAccept = props => {
  const canvasFocusRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      ConfettiAnimation({
        focusElement: canvasFocusRef?.current,
        alignment: 'center',
        zIndex: 1000
      });
    }, 500);
  }, []);

  return (
    <AcceptHomepageWrapper
      badgeId={props.badgeId}
      canvasFocusRef={canvasFocusRef}
      supplementalBottomContentContainerClassName="c-sign-in-accept__bottom-content-container"
      type="signin"
    >
      {
        (badge) => (
          <div className="c-sign-in-accept">
            <div className="c-sign-in-accept__section">
              <SignIn
                formContainerClassName="c-sign-in-accept__form-container"
                formClassName="c-sign-in-accept__form"
                createAccountUrl={chooseSignupUrl(props.badgeId)}
                title="Sign In"
                twoFactorAuth={props.twoFactorAuth}
                badge={badge}
                formIntro={
                  <SigninBadgeRow
                    className="d-flex d-md-none"
                    badge={badge}
                    appearance="min"
                  />
                }
              />
            </div>
          </div>
        )}
    </AcceptHomepageWrapper>
  );
};

SignInAccept.propTypes = {
  badgeId: PropTypes.string.isRequired,
  twoFactorAuth: PropTypes.bool
};

export const testing = { SignInAccept: SignInAccept };
