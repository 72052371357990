import React from 'react';
import PropTypes from 'prop-types';
import { SignIn } from './signin';
import { TranscriptDownloadMarketing } from './transcript_download_marketing';
import { TranscriptDownloadBanner } from 'pages/transcript';
// NOTE: normally, this should be imported via the pages/transcript module; however, that's causing
// an issue with the Jest test suite, which I suspect may be some sort of circular dependency issue
import { withTranscriptDownloadDispatch } from 'pages/transcript/with_transcript_download_dispatch';
import { Routes } from 'utils';

import './sign_in_for_transcript_download.sass';

/**
 * Sign-in view that includes some explanatory/marketing content relating to academic transcript
 * downloads.
 *
 * @property {string} transcriptRecipientId - the ID of the TranscriptDistributionRecipient
 *   associated with the user who is attempting to download the transcript
 * @property {boolean} [twoFactorAuth] - whether the current view should be the 2FA code entry
 * @property {string} userVanitySlug - the vanity slug of the user who sent the transcript
 */
const SignInForTranscriptDownload = ({ userVanitySlug, transcriptRecipientId, twoFactorAuth }) => {
  const createAccountUrl = Routes.transcriptUrl.downloadViaSignup(
    userVanitySlug, transcriptRecipientId
  );

  const signinUrl = Routes.transcriptUrl.downloadViaSignin(
    userVanitySlug, transcriptRecipientId
  );

  return (
    <div className="sign-in-for-transcript-download">
      <TranscriptDownloadBanner/>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <SignIn
              title="Sign in to Credly to accept and share your badge."
              twoFactorAuth={twoFactorAuth}
              createAccountUrl={createAccountUrl}
              signinUrl={signinUrl}
            />
          </div>
          <div className="col-12 col-md-6 sign-in-for-transcript-download__marketing">
            <TranscriptDownloadMarketing/>
          </div>
        </div>
      </div>
    </div>
  );
};

SignInForTranscriptDownload.propTypes = {
  transcriptRecipientId: PropTypes.string.isRequired,
  twoFactorAuth: PropTypes.bool,
  userVanitySlug: PropTypes.string.isRequired
};

const SignInForTranscriptDownloadWithDispatch =
  withTranscriptDownloadDispatch({ isSigninSignup: true })(SignInForTranscriptDownload);
export { SignInForTranscriptDownloadWithDispatch as SignInForTranscriptDownload };

export const testing = { SignInForTranscriptDownload };
