import React from 'react';
import PropTypes from 'prop-types';
import { CreateAccount } from './create_account';
import { CreateAccountAccept } from './create_account_accept';
import { ssoData } from './signin_actions';
import { connect } from 'react-redux';

/**
 * Wraps the regular CreateAccount or CreateAccountAccept views to provide SSO data from the Redux
 * store.
 *
 * @property {object} apiSso - an object containing the user registration data supplied by the SSO
 *   integrator when the token was created; provided by connect
 */
const CreateAccountSSO = ({ apiSso }) => {
  const { badge, user } = apiSso || {};

  let preFill;
  if (apiSso && user) {
    preFill = { ...user };
  }

  if (apiSso && badge && badge.id) {
    return (
      <CreateAccountAccept badgeId={badge.id} preFill={preFill}/>
    );
  } else {
    return (
      <CreateAccount preFill={preFill}/>
    );
  }
};

CreateAccountSSO.propTypes = {
  // provided by connect
  apiSso: PropTypes.shape({
    user: PropTypes.object,
    badge: PropTypes.shape({ id: PropTypes.string })
  })
};

const CreateAccountSSOConnected = connect(
  (state) => {
    const sso = ssoData.getResources(state) || {};

    return {
      // Despite this being called "sso," it is not single-sign-on. Rather, it's a convenience that
      // third parties can use to pre-fill a few fields using a generated token.
      apiSso: sso
    };
  }
)(CreateAccountSSO);

export { CreateAccountSSOConnected as CreateAccountSSO };

export const testing = { CreateAccountSSO };
