import React, { useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Action } from 'utils/action';
import { useScript } from "utils/scripts";
import { LoadingSpinner } from 'controls/loading_spinner';
import { isAppleSSOEnabled } from 'pages/signin/signin_utils';
import { v4 as uuidv4 } from 'uuid';
import AppleLogo from 'images/apple-logo.svg';
import { useAlerts } from 'alert';

import "./apple_sso_button.sass";

// Reference: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
const APPLE_SSO_LOGIN_REDIRECT = '/users/apple_sso';
const APPLE_CLIENT_LIBRARY_SCRIPT_URL = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export const signinViaApple = new Action({
  resourceType: 'signinViaApple',
  requestKey: 'signinViaApple',
  id: 'signinViaApple',
  url: '/users/apple_sso',
  effect: 'update',
  method: 'POST'
});

const AppleSSOButton = ({ badgeId }) => {
  const [appleOAuthState, _setAppleOauthState] = useState(uuidv4());
  const [loading, setLoading] = useState(false);
  const [signinViaAppleActionState, signinViaAppleActionPerform] =
      signinViaApple.useAction();
  const alerts = useAlerts();

  useEffect(() => {
    if (signinViaAppleActionState.resources?.redirectUri) {
      setLoading(false);
      window.location.assign(signinViaAppleActionState.resources.redirectUri);
    }
  }, [signinViaAppleActionState.resources]);

  const scriptCallback = () => {
    let appleCallbackURL = `${document.location.origin}${APPLE_SSO_LOGIN_REDIRECT}`;

    // If sign in with apple is configured, initialize the authorization flow
    // These values are used when signInWithApple is called
    if (isAppleSSOEnabled()) {
      window.AppleID.auth.init({
        clientId: window.initialAppValues.sso.apple.clientId,
        scope: 'name email',
        redirectURI: appleCallbackURL,
        state: appleOAuthState,
        usePopup: true
      });
    } else {
      console.error("Unable to retrieve Apple Client ID.");
    }
  };

  useScript(APPLE_CLIENT_LIBRARY_SCRIPT_URL, { async: true, defer: true, onload: scriptCallback });

  const signInWithApple = async () => {
    setLoading(true);
    try {
      const data = await window.AppleID.auth.signIn();
      /*
        Reference:
        See https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
        Search for the text "Upon authorization success, the server returns the following data object:"
      */
      if (data.authorization.state !== appleOAuthState) {
        setLoading(false);
        alerts.add({
          type: 'error',
          text: 'There was an error logging you in with your Apple account, please try a different method.'
        });
      }
      const code = data.authorization.code;
      const id_token = data.authorization.id_token;
      const badge_id = badgeId;
      const signInWithAppleParams = { id_token, code, badge_id };
      if (data.user) {
        signInWithAppleParams.user = {
          first_name: data.user.name.firstName,
          last_name: data.user.name.lastName
        };
      }
      return signinViaAppleActionPerform(signInWithAppleParams);
    } catch (error) {
      setLoading(false);
      alerts.add({
        type: 'error',
        text: 'There was an error logging you in with your Apple account, please try a different method.'
      });
    }
  };

  return (
    <div className="apple-sso-button__responsive-container">
      <div role="button" onClick={signInWithApple} className="apple-sso-button__apple-sso-button">
        {loading
          ? <LoadingSpinner position="default" size="small" />
          : (
            <>
              <img src={AppleLogo} className="apple-sso-button__logo" />
              <span>
                <FormattedMessage
                  id="sign_in.with_apple_account"
                  defaultMessage="Sign in with Apple"
                />
              </span>
            </>
          )
        }
      </div>
    </div>
  );
};

AppleSSOButton.propTypes = {
  badgeId: PropTypes.string
};

export default AppleSSOButton;
