import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { useScript } from "utils/scripts";

import "./google_sso_button.sass";

const GOOGLE_SSO_BUTTON_ID = 'google_sso_btn';
const GOOGLE_SSO_LOGIN_REDIRECT = '/users/google_sso';
const GOOGLE_CLIENT_LIBRARY_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

const GoogleSSOButton = ({ badgeId }) => {
  const containerRef = useRef(null);
  const [initialized, setInit] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(400);

  const resize = () => {
    if (containerRef.current.offsetWidth !== currentWidth) {
      setCurrentWidth(containerRef.current.offsetWidth);
    }
  };

  const scriptCallback = () => {
    let googleCallbackURL = `${document.location.origin}${GOOGLE_SSO_LOGIN_REDIRECT}`;
    if (!!badgeId) {
      googleCallbackURL += `?badge_id=${badgeId}`;
    }

    if (!!window.initialAppValues.sso.google && !!window.initialAppValues.sso.google.clientId) {
      window.google.accounts.id.initialize({
        client_id: window.initialAppValues.sso.google.clientId,
        ux_mode: 'redirect',
        login_uri: googleCallbackURL
      });
      setInit(true);
    } else {
      console.error("Unable to retrieve Google Client ID.");
    }
  };

  useScript(GOOGLE_CLIENT_LIBRARY_SCRIPT_URL, { async: true, defer: true, onload: scriptCallback });

  useEffect(() => {
    if (initialized) {
      resize();
      window.addEventListener("resize", resize);
      return () => window.removeEventListener("resize", resize);
    }
  }, [initialized]);

  useEffect(() => {
    if (initialized) {
      window.google.accounts.id.renderButton(
        document.getElementById(GOOGLE_SSO_BUTTON_ID),
        { theme: "outline", size: "large", width: currentWidth, text: "continue_with" }
      );
    }
  }, [currentWidth, initialized]);

  return (
    <div className="google-sso-button__responsive-container" ref={containerRef}>
      <div role="button" id={GOOGLE_SSO_BUTTON_ID} className="google-sso-button__g-sso-button"/>
    </div>
  );
};

GoogleSSOButton.propTypes = {
  badgeId: PropTypes.string
};

export default GoogleSSOButton;
