import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Form, connectForm } from 'form';
import { NewHeadingLevel } from 'controls/heading';
import { acceptViaSignin, signin } from './signin_actions';
import { oauthUiOptionsAction } from './oauth_ui_options_action';
import { SigninForm } from './signin_form';
import { SanctionedCountryDialog, showSanctionedCountryDialog } from './sanctioned_country_dialog';
import { isSignInUrlOrRootPath, redirectAfterSignin } from './signin_utils';
import { makeWebdriverId } from 'utils';
import { currentHref, signinUrl, signupUrl } from 'utils/routes';
import urlUtils from 'utils/url';
import { ImmediateProperty } from 'utils/react_utils';
import { intlKeyFromValue } from "translations";
import { LinkWithQuery } from 'utils/link_with_query';

import SocialSignonButtons from "sso/social_signon_buttons";
import './signin.sass';

/**
 * The sign-in page. This can be rendered from the /users/sign_in route, or on any non-anonymous
 * route when you're not signed in.
 *
 * This component is fragile, due to the way it interacts with Form. The signin and TFA forms share
 * the same key, so that they're the same form as far as React is concerned, in order to share
 * certain errors. Because of this, whenever a change is made to signin logic (including bug fixes),
 * the following paths should be tested:
 * - With TFA disabled:
 *   - Sign in correctly.
 *   - Sign in with a username which is not an email address. You should get a client validation
 *     error. The password field should be cleared.
 *   - Sign in with a valid username but invalid password. You should get a server validation error.
 *     The password field should be cleared.
 * - With TFA enabled:
 *   - Sign in correctly.
 *   - Sign in with a valid username/password, and invalid TFA code. You should get redirected to
 *     the signin page with an error.
 *     - Do the same, but with no TFA code. You should get a client validation error.
 *       - After doing this, press the browser's Back button. You should see no error.
 *   - Sign in with a valid username, invalid password, and valid TFA code. You should get
 *     redirected to the signin page with an error.
 *
 * If you get locked out while testing, set locked_at=NULL and failed_attempts=0 in the DB.
 *
 * @property {String} title - The section title.
 * @property {Boolean} twoFactorAuth - Show the two-factor-auth UI.
 */
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetForm: new ImmediateProperty(),
      sanctionedCountryDialog: showSanctionedCountryDialog(props.location?.search)
    };
    try {
      this.state.rememberMeInitialState = localStorage.getItem('remember_remember_me') === '1';
    } catch (e) {
      // Ignore
    }
  }


  /**
   * The component just mounted.
   */
  componentDidMount() {
    this.historyUnlisten = this.props.history.listen(this.onLocationChange);
    this.onLocationChange(this.props.history.location);
  }


  /**
   * The component is about to unmount; stop listening to history location changes.
   */
  componentWillUnmount() {
    this.historyUnlisten();
  }

  /**
   * The history location changed.
   *
   * @param {{ pathname: string }} location - the current location
   */
  onLocationChange = location => {
    this.setState({ reloading: false });
  };


  /**
   * Called right before the signin form (but not the TFA form) submits.
   *
   * @param {{remember_me: boolean}} values - Form values.
   */
  onBeforeSubmit = values => {
    try {
      localStorage.setItem('remember_remember_me', values.remember_me ? '1' : '0');
    } catch (e) {
      // Ignore
    }
  };

  /**
   * For certain sign in flows such as those that involve multi step processes (2fa) there may be
   * things in local storage etc. that we need to clean up. This method was created as a descriptive
   * method to indicate actions that should be executed when the user is actually signing in vs.
   * going to another step in the sign in process so we can safely clean up persisted values.
   */
  cleanUpAfterSuccessfulLogin = () => {
    localStorage.removeItem('redirectAfterTwoFactorAuthUrl');
  };


  /**
   * Signin (or at least one step of signin) succeeded.
   */
  success = () => {
    const api = this.props.api;
    const history = this.props.history;

    // If two-factor auth is required, push a url so that the back button works.
    if (api.signinResources.two_factor_auth) {
      const newParams = { twoFactorAuth: '1' };
      const urlParams = new URLSearchParams(this.props.history.location.search);

      if (urlParams.has('oauthAppId')) {
        newParams['oauthAppId'] = urlParams.get('oauthAppId');
      }
      if (!isSignInUrlOrRootPath()) {
        localStorage.setItem('redirectAfterTwoFactorAuthUrl', currentHref());
      }
      this.setState({ reloading: true, resetForm: this.state.resetForm.next() });
      history.push(urlUtils.appendParams(this.signinUrl(), newParams));
    }
    // Most success/failure cases are handled server-side.
    // /badges/:id/accept can redirect to any of these urls:
    // - /profile/badges
    // - /profile/badges/:id
    // - /secondary_email_addresses/unconfirmed/:id
    // - /profile/onboarding/badge/:id
    // - /badges/:id/twitter
    // - /badges/:id/accept  (for replacement badge)
    else {
      // we're actually signing in
      this.setState({ reloading: true });
      let redirectUrl;
      if (this.props.twoFactorAuth && localStorage.getItem('redirectAfterTwoFactorAuthUrl')) {
        redirectUrl = localStorage.getItem('redirectAfterTwoFactorAuthUrl');
      } else {
        redirectUrl = api.signinResources.redirect;
      }
      this.cleanUpAfterSuccessfulLogin();
      redirectAfterSignin(redirectUrl);
    }
  };


  /**
   * Two-factor authentication has failed. The error message is always either "your email or
   * password is invalid," or "you've reached the maximum number of attempts to log in." Current
   * expected behavior is that we redirect to the signin page and show the error.
   *
   * @param {Object} failProps
   *   @param {array<string>} failProps.globalErrors - Errors not attached to a field.
   *   @param {array<string>} failProps.fieldErrors - Errors attached to a field.
   *   @param {function(array|string)} failProps.clearErrors - Remove field values.
   */
  twoFactorFailure = failProps => {
    // If client-side validation failed, or an error occurred in the TFA form, don't redirect.
    if (failProps.fieldErrors.length === 0) {
      // Reset so that otp values won't be sent on the next signin form submit.
      failProps.clearErrors('otp_attempt');
      this.setState({ resetForm: this.state.resetForm.next() });
      const urlParams = new URLSearchParams(this.props.location.search);
      const params = {};
      if (urlParams.has('oauthAppId')) {
        params.oauthAppId = urlParams.get('oauthAppId');
      }
      this.props.history.push(urlUtils.appendParams(this.signinUrl(), params));
      this.failure(failProps);
    }
  };


  /**
   * Submit failed.
   *
   * @param {Object} failProps
   *   @param {array<string>} failProps.globalErrors - Errors not attached to a field.
   *   @param {array<string>} failProps.fieldErrors - Errors attached to a field.
   *   @param {function(array|string)} failProps.removeValues - Remove field values.
   *   @param {function(array|string)} failProps.clearErrors - Remove field values.
   */
  failure = failProps => {
    // Some errors (like :unconfirmed) result in a redirect.
    const api = this.props.api;
    const statusCode = api.signinRequest.statusCode;
    const redirect = api.signinRequest.result && api.signinRequest.result.redirect;

    if (redirect) {
      redirectAfterSignin(redirect);
    } else {
      // Remove the password field, but leave username alone.
      failProps.removeValues({ password: 1 });

      if (statusCode === 451) {
        this.setState({ sanctionedCountryDialog: true });
      }
    }
    this.setState({ reloading: false });
  };


  /**
   * Get the signup url appropriate for this page.
   *
   * @returns {String}
   */
  signupUrl() {
    if (this.uiOpts().hide_sign_up) {
      return '';
    }
    return this.props.createAccountUrl || signupUrl(this.props.badge ? this.props.badge.id : null);
  }


  /**
   * Get the signin url appropriate for this page.
   *
   * @returns {String}
   */
  signinUrl() {
    return this.props.signinUrl || signinUrl(this.props.badge ? this.props.badge.id : null);
  }

  renderSignInInputs() {
    const result = [
      {
        component: Form.TextField,
        name: 'email',
        label: (
          <FormattedMessage
            id="label.email"
            defaultMessage="Email"
          />),
        required: true,
        type: 'email'
      },
      {
        component: Form.PasswordField,
        name: 'password',
        label: (
          <FormattedMessage
            id="label.password"
            defaultMessage="Password"
          />),
        required: true
      }
    ];
    if (!this.uiOpts().hide_remember_me) {
      result.push({
        component: Form.Checkbox,
        name: 'remember_me',
        className: 'signin__remember-me',
        label: (
          <FormattedMessage
            id="label.remember_me"
            defaultMessage="Remember me"
          />)
      });
    }
    return result;
  }

  renderSsoContent() {
    if (this.uiOpts().hide_social_sso) {
      return null;
    }

    return <SocialSignonButtons badgeId={this.props.badge?.id}/>;
  }

  uiOpts() {
    return this.props.api.oauthUiOptionsResources || {};
  }

  /**
   * Render either the sign-in page or the two-factor auth page. These are in the same component
   * due to shared logic, and to make React see these two modes as having the same Form, so they can
   * share error messages.
   *
   * @returns {React.element}
   */
  render() {
    // (isTfa && !auth) should only happen if the user goes to the TFA url directly. In that case,
    // signin will certainly fail. We could fix it by showing signin in that case, but then, after
    // successful TFA signin, the user would see the signin page while the new page is loading.
    const auth = this.props.api.signinResources?.two_factor_auth || {};
    const isTfa = this.props.twoFactorAuth;
    const uiOpts = this.uiOpts();
    const { sanctionedCountryDialog } = this.state;
    const closeSanctionedCountryDialog = () => {
      this.setState({ sanctionedCountryDialog: false });
    };

    const makeButtonContent = (defaultContent) => {
      return uiOpts.action_button_text || defaultContent;
    };

    const signinFormCommonAttrs = {
      ...this.props,
      resetNow: this.state.resetForm,
      onSubmitSuccess: this.success,
      singleUse: true,
      useMinimalForm: uiOpts.use_minimal_sign_in_screen
    };

    const queryString = this.props.location?.search;
    const urlParams = new URLSearchParams(queryString);
    const invitationRecipientEmail = urlParams.get('invitation_recipient_email');

    // Two-factor auth code UI
    if (isTfa) {
      // hide the create account option once the user reaches the 2FA entry
      delete signinFormCommonAttrs.createAccountUrl;

      return (
        <NewHeadingLevel>
          <SigninForm
            {...signinFormCommonAttrs}
            onSubmitFailure={this.twoFactorFailure}
            buttonText={makeButtonContent(
              <FormattedMessage
                id="sign_in_two_factor_authentication.title"
                defaultMessage="Two-Factor Authentication"
              />
            )}
            webdriverId={makeWebdriverId('signin-tfa')}
            subtitle={
              <FormattedMessage
                id="sign_in_two_factor_authentication.subtitle"
                defaultMessage="In order to access Credly, you must enter a validation code from your authenticator application."
              />
            }
            initialValues={{
              nonce_token: auth.token,
              data: auth.data
            }}
            inputs={[
              {
                component: Form.TextField,
                name: 'otp_attempt',
                label: (
                  <FormattedMessage
                    id="sign_in_two_factor_authentication.authorization_code"
                    defaultMessage="Authorization Code"
                  />),
                autoFocus: true,
                required: true
              }
            ]}
          />
        </NewHeadingLevel>
      );
    } else { // Sign-in UI
      return (
        <NewHeadingLevel>
          <SigninForm
            {...signinFormCommonAttrs}
            onBeforeSubmit={this.onBeforeSubmit}
            onSubmitFailure={this.failure}
            additionalSubtext={uiOpts.subtext}
            buttonText={makeButtonContent(
              <FormattedMessage
                id="button.sign_in"
                defaultMessage="Sign In"
              />
            )}
            title={
              <FormattedMessage
                id={intlKeyFromValue(this.props.title, "sign_in")}
                defaultMessage={this.props.title}
              />
            }
            createAccountUrl={this.signupUrl()}
            webdriverId={makeWebdriverId('signin-main')}
            initialValues={{
              email: invitationRecipientEmail,
              remember_me: this.state.rememberMeInitialState
            }}
            simulateSubmitting={this.state.reloading}
            inputs={this.renderSignInInputs()}
            otherButton={
              <LinkWithQuery to="/users/password/new" className="signin__forgot-password">
                <FormattedMessage
                  id="label.forgot_your_password"
                  defaultMessage="Forgot your password?"
                />
              </LinkWithQuery>
            }
            ssoContent={this.renderSsoContent()}
            dialogs={[
              <SanctionedCountryDialog
                key="sanctioned-country-dialog"
                show={sanctionedCountryDialog}
                shouldClose={closeSanctionedCountryDialog}
              />
            ]}
          />
        </NewHeadingLevel>

      );
    }
  }
}

SignIn.propTypes = {
  createAccountUrl: PropTypes.string,
  title: PropTypes.string,
  twoFactorAuth: PropTypes.bool,
  badge: PropTypes.object,
  api: PropTypes.shape({
    oauthUiOptionsResources: PropTypes.shape({
      action_button_text: PropTypes.string,
      hide_remember_me: PropTypes.bool,
      hide_sign_up: PropTypes.bool,
      hide_social_sso: PropTypes.bool,
      subtext: PropTypes.string,
      use_minimal_sign_in_screen: PropTypes.bool
    })
  })
};

SignIn.defaultProps = {
  title: 'Sign In',
  uiOptions: {
    hideRememberMe: false,
    hideSignUp: false
  }
};

const Connected = connectForm({ resourceHandler: signin, valuesSource: 'props' })(
  withRouter(connect(
    (state, ownProps) => {
      const params = new URLSearchParams(ownProps.location.search);
      const oauthAppId = params.get('oauthAppId');
      return {
        api: {
          signinRequest: signin.getRequestDetails(state) || {},
          signinResources: signin.getResources(state) || {},
          acceptStatus: acceptViaSignin.getStatus(state),
          acceptResources: acceptViaSignin.getResources(state) || {},
          // The following data is already available in the store via
          // app/views/users/sessions/new.html.haml
          oauthUiOptionsResources: oauthUiOptionsAction.get('oauthUiOptions', oauthAppId || 'fake').getResources(state) || {}
        }
      };
    },
    dispatch => ({
      actions: {
        reset: () => dispatch(signin.reset()),
        accept: params => dispatch(acceptViaSignin.action(params))
      }
    })
  )(SignIn))
);



export { Connected as SignIn };

export const testing = { SignIn: SignIn };
