import React from 'react';
import { Heading } from 'controls/heading';

// NOTE: if you replace this image, you should also update or remove the max-width defined for the
// image in the SASS file; ideally, that value would be 1x or 1/2x the actual size of the image
import SampleTranscriptImage from 'images/sample-transcript.png';
import './transcript_download_marketing.sass';

/**
 * Component that just contains static content explaining what the user needs to do in order to
 * download the transcript that was shared with them.
 */
export const TranscriptDownloadMarketing = () => {
  return (
    <div className="transcript-download-marketing">
      <Heading appearance="custom" className="transcript-download-marketing__heading">
        Create an account or sign in to download the transcript.
      </Heading>
      <div className="transcript-download-marketing__description">
        An official transcript documenting verified learning outcomes has been shared with you.
        Please create an account or sign in to download a copy for your records.
      </div>
      <div className="transcript-download-marketing__sample">
        <img
          src={SampleTranscriptImage}
          alt=""
          className="transcript-download-marketing__sample-img"
        />
        <div className="transcript-download-marketing__sample-caption">Sample Transcript</div>
      </div>
    </div>
  );
};
