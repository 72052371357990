import { lazyWithRetry } from 'app_utils/lazy_load';

export { transcriptDistributionRecipients } from './action_creators';
export { withTranscriptDownloadDispatch } from './with_transcript_download_dispatch';

export const EarnerTranscriptRoutes = lazyWithRetry(() =>
  import(/* webpackChunkName: "transcript" */ './earner_transcript_routes')
    .then(module => ({default: module.EarnerTranscriptRoutes}))
);

export const UpdateProfileForDownload = lazyWithRetry(() =>
  import(/* webpackChunkName: "transcript" */ './update_profile_for_download')
    .then(module => ({default: module.UpdateProfileForDownload}))
);

export const TranscriptDownloadBanner = lazyWithRetry(() =>
  import(/* webpackChunkName: "transcript" */ './transcript_download_banner')
    .then(module => ({default: module.TranscriptDownloadBanner}))
);
