import { ActionManager } from 'utils/action_manager';

export const oauthUiOptionsAction = new ActionManager('oauthUiOptions')
  .add({
    method: 'GET',
    effect: 'read',
    url: '/api/v1/oauth/v2/applications/[id]/ui_options',
    requestKey: 'oauthUiOptions'
  }, true);

