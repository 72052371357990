import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { HidesHeaderButtons } from 'header/hides_header_buttons';
import { Form, useFormProps } from 'form';
import { resendEmailConfirmation } from './signin_actions';
import { FormattedMessage } from 'react-intl';

import './confirm_your_email.sass';

// TODO: Merge this component with AlmostDone
const ConfirmYourEmail = (props) => {
  const [formProps] =
    useFormProps(
      resendEmailConfirmation,
      { initialValues: { user_id: props.userId } }
    );

  return (
    <HidesHeaderButtons>
      <Form {...formProps} >
        {({ isSubmitting }) => (
          <div className="container confirm-your-email">
            <div className="confirm-your-email__icon-container">
              <FontAwesomeIcon
                className="confirm-your-email__icon-email"
                icon={faEnvelope}
              />
              <div aria-hidden="true" className="confirm-your-email__count-badge">
            1
              </div>
            </div>
            <Heading appearance="badge-name-hero">
              <FormattedMessage
                id="create_account.confirm_your_email"
                defaultMessage="Confirm Your Email"
              />
            </Heading>

            <div className="confirm-your-email__text">
              <div>
                <FormattedMessage
                  id="create_account.almost_done_1"
                  defaultMessage="Thank you for creating an account with Credly!"
                />
              </div>
              <div>
                <FormattedMessage
                  id="create_account.almost_done_2"
                  defaultMessage="To finish setting up your account, please check your email to confirm the address you
              provided."
                />
              </div>
            </div>
            <div>
              <Form.Submit
                type="primary"
                loading={isSubmitting}
              >
                <FormattedMessage
                  id="create_account.resend_email_confirmation"
                  defaultMessage="Resend Email Confirmation"
                />
              </Form.Submit>
            </div>
          </div>
        )}
      </Form>
    </HidesHeaderButtons>
  );
};

ConfirmYourEmail.propTypes = {
  userId: PropTypes.string.isRequired
};

export default ConfirmYourEmail;
