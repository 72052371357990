import { LocationData } from "utils/location_data";

let processedLocationData;

if (window.initialAppValues) {
  processedLocationData = new LocationData();
  processedLocationData.configureLocationData(
    window.initialAppValues.locationData
  );
}

export { processedLocationData };
