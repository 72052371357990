import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Wrap a component in this to hide the "create account" and "sign in" buttons.
 */

export const HidesHeaderButtons = props => {
  React.useEffect(() => {
    // Show or hide signin and create account buttons.
    const toggleButtons = on => {
      const headerButtons = document.getElementsByClassName('header-nav-item--btn-container');
      for (var i = 0; i < headerButtons.length; i++) {
        headerButtons[i].style.display = on ? '' : 'none';
      }
    };

    // Hide buttons on mount.
    toggleButtons(false);
    // Show buttons on unmount.
    return () => toggleButtons(true);
  }, []);

  return props.children || '';
};


HidesHeaderButtons.propTypes = {
  children: PropTypes.any
};
