import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { FormattedMessage } from 'react-intl';

/**
 * A dialog that displays a message for sanctioned countries.
 *
 * @property {function} shouldClose - Called when the dialog is closed.
 * @property {boolean} show - Whether to show the dialog or not.
 */
export const SanctionedCountryDialog = (props) => {
  return (
    <Dialog
      title={
        <FormattedMessage
          id="sanctioned_country_dialog.title"
          defaultMessage="Unavailable in your region"
        />
      }
      size="half"
      show={props.show}
      shouldClose={props.shouldClose}
    >
      <Dialog.Content>
        <FormattedMessage
          id="sanctioned_country_dialog.body"
          defaultMessage="Body"
        />&nbsp;
        <a
          href="https://support.credly.com/hc/en-us/articles/6773677121051-How-do-sanctions-impact-accounts-on-Credly-"
          target="_blank"
        >
          <FormattedMessage
            id="sanctioned_country_dialog.learn_more"
            defaultMessage="Learn more"
          />
        </a>
      </Dialog.Content>
    </Dialog>
  );
};

SanctionedCountryDialog.propTypes = {
  shouldClose: PropTypes.func,
  show: PropTypes.bool
};

export const showSanctionedCountryDialog = (search) => {
  const urlParams = new URLSearchParams(search);
  return urlParams.get('sanctioned_country') === '1';
};
