import { actionTypes } from 'redux-resource';
import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const transcriptDistributions = (new ActionManager('transcriptDistributions'))
  .add({
    requestKey: 'create',
    url: '/api/v1/users/self/transcript_distributions',
    list: 'created',
    effect: 'create',
    method: 'POST'
  })
  .add({
    requestKey: 'update',
    url: '/api/v1/users/self/transcript_distributions/[id]',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id', remove: true }
    }),
    effect: 'update',
    method: 'PUT'
  }, true)
  .add({
    requestKey: 'submit',
    url: '/api/v1/users/self/transcript_distributions/[id]/submit',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id', remove: true }
    }),
    effect: 'update',
    method: 'POST'
  }, true);

export const clearTranscriptDistributionCreatedList = () => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    lists: {
      transcriptDistributions: {
        created: []
      }
    }
  });
};

export const transcriptDistributionRecipients = (new ActionManager('transcriptDistributionRecipients'))
  .add({
    requestKey: 'get',
    url: '/api/v1/transcript_distribution_recipients/[id]',
    effect: 'read',
    method: 'GET'
  }, true);

export const fetchTranscriptBadges = new Action({
  resourceType: 'earnedBadges',
  requestKey: 'get',
  url: '/api/v1/users/self/badges',
  effect: 'read',
  list: 'transcriptBadges',
  method: 'GET',
  alwaysRefresh: true,
  defaultParams: { included_on_transcript: true, page_size: 1 }
});

