import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';
import * as routes from 'utils/routes';

export const signin = new Action({
  resourceType: 'temp',
  requestKey: 'signin',
  id: 'signin',
  url: '/users/sign_in',
  effect: 'update',
  method: 'POST',
  alwaysRefresh: true
});

export const recoverPassword = new Action({
  resourceType: 'temp',
  requestKey: 'recoverPassword',
  id: 'recoverPassword',
  url: '/users/password',
  effect: 'update',
  method: 'POST'
});

export const changePassword = new Action({
  resourceType: 'temp',
  requestKey: 'changePassword',
  id: 'changePassword',
  url: '/users/password',
  effect: 'update',
  method: 'PUT'
});

const checkIfValid = new Action({
  requestKey: 'createAccount',
  resourceType: 'createAccount',
  url: '/registrations/check_if_valid',
  effect: 'update',
  method: 'POST',
  key: 'user'
});

// TODO: Change these action wrappers to use prepareSigninSubmit() instead.
const origCheckIfValidAction = checkIfValid.action;
checkIfValid.action = (params) => origCheckIfValidAction({ user: params });
export { checkIfValid };

const createNewUserAccount = new Action({
  requestKey: 'createAccount',
  resourceType: 'createAccount',
  url: '/users',
  effect: 'update',
  method: 'POST',
  key: 'user'
});

const origCreateNewUserAccountAction = createNewUserAccount.action;
createNewUserAccount.action = (params) => origCreateNewUserAccountAction({ user: params });
export { createNewUserAccount };

export const acceptViaSignin = new Action({
  requestKey: 'acceptViaSignin',
  resourceType: 'temp',
  id: 'acceptViaSignin',
  url: '/badges/[id]/accept',
  effect: 'read',
  method: 'GET',
  urlSubs: new stringUtils.BracketSubs({
    id: { param: 'id', remove: true }
  })
});

const createSSOUserAccount = new Action({
  requestKey: 'createAccount',
  resourceType: 'createAccount',
  url: '/users/registrations/sso',
  effect: 'update',
  method: 'POST',
  key: 'user'
});

const origcreateSSOUserAccountAction = createSSOUserAccount.action;
createSSOUserAccount.action = (params) => origcreateSSOUserAccountAction({
  user: params,
  token: params.token,
  organization_id: params.organization_id,
  social: params.social
});
export { createSSOUserAccount };

// This is not meant to be called as a URL. It just reaches into the redux store, which was
// initialized in initPrerenderData.
export const ssoData = new Action({
  resourceType: 'sso',
  requestKey: 'sso',
  id: 'sso',
  url: '/FAKE_URL',
  effect: 'read',
  method: 'GET'
});

const resendEmailConfirmation = new Action({
  url: '/users/unconfirmed',
  method: 'POST',
  requestKey: 'resendEmailConfirmation',
  effect: 'read',
  resourceType: 'temp',
  addCustomHeaders: (headers) => {
    headers.Accept = '*/*';
    return headers;
  },
  onSuccess: (dispatch, action, res) => {
    routes.setPath(res.res.body.data.redirect);
  }
});

const origResendEmailConfirmation = resendEmailConfirmation.action;
resendEmailConfirmation.action = (params) => origResendEmailConfirmation({
  user_id: params.user_id
});
export { resendEmailConfirmation };

export const nonceTokensActions = (new ActionManager('nonceTokens'))
  .add({
    requestKey: 'show',
    url: '/api/v1/nonce_tokens/[id]',
    effect: 'read',
    method: 'GET',
    alwaysRefresh: true
  }, true);

// This is not meant to be called as a URL. It just reaches into the redux store, which was
// initialized in initPrerenderData.
export const ssoTfa = new Action({
  resourceType: 'ssoTfa',
  requestKey: 'ssoTfa',
  id: 'ssoTfa',
  url: '/FAKE_URL',
  effect: 'read',
  method: 'GET'
});

export const ssoTfaSubmit = new Action({
  resourceType: 'temp',
  requestKey: 'ssoTfaSubmit',
  id: 'ssoTfaSubmit',
  url: '/users/sessions/sso',
  effect: 'read',
  method: 'GET',
  alwaysRefresh: true
});
